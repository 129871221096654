export const RANKING_V2_PAGE = {
  CONTENT: 'methodology-content',
  COVERAGE_MODULE: 'ranking-list-page-coverage-module',
  FAQ_TITLE: 'faq-title',
  FILTER_CURRENT_PAGE_INPUT: 'filters-current-page-input',
  FILTER_FORM_CHECKOUT: 'filter-form-checkout',
  FILTER_FORM_DROPDOWN: 'filter-form-dropdown',
  FILTER_NEXT_PAGE: 'filter-next-page',
  FILTER_NO_RESULTS: 'no-results',
  FILTER_PREV_PAGE: 'filter-previous',
  FILTER_ROWS_TO_SHOW: 'filter-rows-to-show',
  FILTER_TITLE: 'filters-title',
  LIST_CELL: 'list-cell',
  LIST_ROW: 'list-row',
  METHODOLOGY_TITLE: 'methodology-title',
  MORE_RANKINGS_BUTTON: 'more-rankings-button',
  MORE_RANKINGS_IMAGE: 'more-rankings-image',
  MORE_RANKINGS_IMAGE_LINK: 'more-rankings-image-link',
  MORE_RANKINGS_ITEM: 'more-rankings-item',
  MORE_RANKINGS_MAIN_TITLE: 'more-rankings-main-title',
  MORE_RANKINGS_MORE_YEARS: 'more-rankings-more-years',
  MORE_RANKINGS_MORE_YEARS_LINK: 'more-rankings-more-years-link',
  MORE_RANKINGS_TITLE: 'more-rankings-title',
  MORE_RANKINGS_YEAR_LINK: 'more-rankings-year-link',
  OPEN_CLOSE_FILTER: 'open-close-filter',
  RANKING_HIGHLIGHTS_ARROW_LINK: 'ranking-highlights-arrow-link',
  RANKING_HIGHLIGHTS_ARROW_NEXT: 'ranking-highlights-arrow-next',
  RANKING_HIGHLIGHTS_ARROW_PREV: 'ranking-highlights-arrow-prev',
  RANKING_HIGHLIGHTS_CARD: 'ranking-highlights-card',
  RANKING_HIGHLIGHTS_CHECKMARK: 'ranking-highlights-checkmark',
  RANKING_HIGHLIGHTS_DESCRIPTION: 'ranking-highlights-description',
  RANKING_HIGHLIGHTS_RANK: 'ranking-highlights-rank',
  RANKING_HIGHLIGHTS_TITLE: 'ranking-highlights-title',
  RANKING_HIGHLIGHTS_TITLE_COMPANY: 'ranking-highlights-title-company',
  RANKING_LIST: 'ranking-list-wrapper',
  RANKING_LIST_ITEM: 'ranking-list-item',
  RANKING_LIST_ITEM_YEARS: 'ranking-list-item-years',
  RANKING_LIST_TITLE: 'ranking-list-title',
  RESET_FILTER_BUTTON: 'reset-filter-button',
  SEARCH: 'search',
  SEE_ALL_CTA: 'see-all-cta',
  STN_VIDEO: 'stn-video',
  TABS: 'methodology-tabs',
  VIDEO: 'ranking-video',
  VIDEO_POSTER: 'video-poster',
  YEAR_FILTER: 'year-filter',
};

export const FAQ_ACCORDION = {
  ARROW: 'faq-accordion-arrow',
  CONTENT: 'faq-accordion-content',
  TITLE: 'faq-accordion-title',
  TOGGLE_BUTTON: 'faq-accordion-toggle-button',
  WRAPPER: 'faq-wrapper',
};

export const FEATURED_IMAGE = {
  FI_CTA: 'fi-cta',
  FI_DESCRIPTION: 'fi-description',
  FI_TITLE: 'fi-title',
  HERO_IMAGE: 'fi-hero-image',
  WRAPPER: 'fi-wrapper',
};

export type AccordionTestIds = {
  [key in keyof typeof FAQ_ACCORDION]?: string;
};

export const VISUALIZE = {
  BUTTON_LINK: 'visualize-button-link',
  CONTENT: 'visualize-content',
  IMAGE: 'visualize-image',
  IMAGE_LINK: 'visualize-image-link',
  TITLE: 'visualize-title',
};

export const RANKING_ANALYTICS = {
  IMAGE: 'ra-image',
  RA_CTA: 'ra-cta',
  RA_CTA_EXPLORE: 'ra-cta-explore',
  RA_DESCRIPTION: 'ra-description',
  RA_ITEM: 'ra-item',
  RA_TITLE: 'ra-title',
  WRAPPER: 'ra-wrapper',
};
